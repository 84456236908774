#services-section {
  min-width: 100vw;
  min-height: calc(100vh - 75px);
}

.service-card-image {
  object-fit: cover;
  border-radius: 8%;
  max-height: 400px;
  aspect-ratio: 3/2;
}

#services-title {
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 1));
}
