.nav-bar {
  background-color: rgba(60, 60, 100, 0.03);
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  transition: 2s ease all !important;
}

.active {
}

.inactive {
  mix-blend-mode: difference;
}

.browser-link {
  text-decoration: none;
  font-size: 1.2em;
  /* transition: 0.1s ease-in-out all;*/
}

.browser-link:hover {
  color: rgb(255, 255, 255) !important;
}
