#img-modal__container {
  /* background-color: rgba(0, 0, 0, 0.8); */

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  height: 100vh;
  width: 100vw;
  z-index: 100000;
  overflow: hidden;
  position: absolute;
  top: 0;
}

#img-modal__active-item__container {
  min-height: 100vh;
}

#active-item__img {
  max-height: 100vh;
  max-width: 100vw;
}

.close-btn {
  z-index: 1000;
}

.menu-btn {
  z-index: 1000;
}

.img-modal__footer-container {
  background-color: rgba(0, 0, 0, 0.6);

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

#img-modal__footer {
}

#img-modal__footer-innner {
  max-height: 95dvh;
  max-width: 100vw;
  overflow: auto;
}

.img-modal__footer__img {
  object-fit: cover;
  width: 12.5rem;
  height: 7.03125rem;
  vertical-align: middle;
  box-sizing: border-box;
}

@media only screen and (max-width: 764px) {
  .img-modal__footer__img {
    width: 6.5rem;
    height: 4.5125rem;
  }
}

.tools-container {
  z-index: 10000;
}
