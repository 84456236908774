#clients-section {
  min-width: 100vw;
  min-height: calc(100vh - 75px);
}

#clients-description-container::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://ik.imagekit.io/pwdev/Disdecor/Clients/lasers_1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  opacity: 0.1;
}

.client-logo {
  width: 10vw;
  min-width: 150px;
  transition: 0.5s ease-in-out all;
}

.client-logo_image {
  width: 100%;
}

#clients-title {
  min-width: 150px;
}

@media only screen and (max-width: 764px) {
  .client-logo {
    min-width: 100px;
  }
}
