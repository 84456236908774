.clients-marquee-item-container {
  height: 200px;
  margin: 20px;
  width: 200px;
}

.clients-marquee-item {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#intro-section {
  width: 100vw;
  padding-top: 75px;
}

@media only screen and (max-width: 1024px) {
  /* #intro-section {
    height: 30vh !important;
  }*/
  .clients-marquee-item-container {
    height: 150px;
    margin-bottom: 20px;
    width: 200px;
  }
}

.landing-carousel {
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0)
  );
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 0)
  );
  z-index: -1;
}

.landing-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-item-container {
  min-height: 500px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.glow {
  text-shadow: -1px -1px 1px var(--shadow-color-light),
    -1px 1px 1px var(--shadow-color-light),
    1px -1px 1px var(--shadow-color-light),
    1px 1px 1px var(--shadow-color-light), 0 0 3px var(--shadow-color-light),
    0 0 10px var(--shadow-color-light), 0 0 20px var(--shadow-color-light),
    0 0 30px var(--shadow-color), 0 0 40px var(--shadow-color),
    0 0 50px var(--shadow-color), 0 0 70px var(--shadow-color),
    0 0 100px var(--shadow-color), 0 0 200px var(--shadow-color);
  color: white;
  font-weight: 100;
}

.intro-info {
  flex: 1;
}

.intro-container {
  display: flex;
  flex-direction: column;
}

.intro-info-box {
}

.landing-carousel-item {
  transition: transform 3s ease-in-out;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  transition: opacity 0s 3s;
}
