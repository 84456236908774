#footer {
}

#footer-logo {
  height: 100px;
  width: 100px;
}

#footer-logo-title {
  width: 300px;
}
