#about-section {
  min-height: calc(100vh - 75px);
  width: 100vw;
}

.about-description-container::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://ik.imagekit.io/pwdev/Disdecor/About/blue-club.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  opacity: 0.1;
}

.about-description {
}

#about-logo {
  height: 10rem;
}

#about-logo-container {
}

@media only screen and (max-width: 979px) {
  #about-stack {
  }
}
