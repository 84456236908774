:root {
  --shadow-color: rgba(0, 69, 90, 0.829);
  --shadow-color-light: rgba(0, 64, 148, 0.9);
  height: 100vh;
  background-color: black;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

.App {
  text-align: center;
  background: rgb(0, 0, 0, 1);
  width: 100vw;
  overflow-x: hidden;
  isolation: isolate;
  top: 0;
}

.App-link {
  color: #61dafb;
}

.clickable {
  cursor: pointer;
}

.fade-x {
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0.5) 90%,
    rgba(0, 0, 0, 0) 100%
  );
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 10%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0.5) 90%,
    rgba(0, 0, 0, 0) 100%
  );
}

.glass-card {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 25px 25px 25px 25px;
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(50px) !important;
  -webkit-backdrop-filter: blur(50px) !important;
  max-width: 1024px;
}

.glass {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 12px 2px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(50px) !important;
  -webkit-backdrop-filter: blur(50px) !important;
}

.gradient-background {
  background: linear-gradient(
    300deg,
    #3e0020,
    #000000,
    #000021,
    #000000,
    #3e0020
  );
  background-size: 240% 240%;
  animation: gradient-animation 20s ease-in-out alternate infinite;
  position: absolute;
  z-index: -1;
}

.gradient-background::after {
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-image: url("https://ik.imagekit.io/pwdev/Disdecor/About/aboutbg.png");
  background-repeat: repeat-x;
  z-index: -2;
}

.gap-filler {
  height: 10vh;
}

.glassbtn {
  position: relative;
  display: inline-block;
  border: none;
  border-radius: 50px;
  background: none;
  padding: 25px 75px;
  margin: 30px;
}

.glassbtn span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  color: #fff;
  z-index: 1;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(15px);
}

.glassbtn:hover span {
  letter-spacing: 3px;
}

.glassbtn span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  transform: skewX(40deg) translateX(0);
  transition: all 0.5s ease-out;
}

.glassbtn:hover span::before {
  transform: skewX(40deg) translateX(200%);
}

.glassbtn::before,
.glassbtn::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 10px;
  border-radius: 10px;
  background: #f00;
  transition: all 0.4s ease-in-out;
  transition-delay: 0s;
}

.glassbtn::before {
  bottom: -5px;
}

.glassbtn::after {
  top: -5px;
}

.glassbtn:hover::before,
.glassbtn:hover::after {
  height: 50%;
  width: 80%;
  border-radius: 30px;
  transition-delay: 0.3s;
}

.glassbtn:hover::before {
  bottom: 0;
}

.glassbtn:hover::after {
  top: 0;
}

.glassbtn:nth-child(1)::before,
.glassbtn:nth-child(1)::after {
  background: #ff7979;
  box-shadow: 0 0 5px #ff7979, 0 0 15px #ff7979, 0 0 30px #ff7979,
    0 0 60px #ff7979;
}

.glassbtn:nth-child(2)::before,
.glassbtn:nth-child(2)::after {
  background: #2bbfff;
  box-shadow: 0 0 5px #2b8eff, 0 0 15px #2bd2ff, 0 0 30px #2bd2ff,
    0 0 60px #2bd2ff;
}

.glassbtn:nth-child(3)::before,
.glassbtn:nth-child(3)::after {
  background: #1eff45;
  box-shadow: 0 0 5px #1eff45, 0 0 15px #1eff45, 0 0 30px #1eff45,
    0 0 60px #1eff45;
}

.glassbtn:nth-child(4)::before,
.glassbtn:nth-child(4)::after {
  background: #fffa65;
  box-shadow: 0 0 5px #fffa65, 0 0 15px #fffa65, 0 0 30px #fffa65,
    0 0 60px #fffa65;
}

.heavy-shadow-text {
  font-size: 48px;
  font-weight: bold;
  color: #fff; /* Text color */
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7),
    /* First shadow */ -3px -3px 5px rgba(0, 0, 0, 0.7),
    /* Second shadow */ 5px 5px 10px rgba(0, 0, 0, 0.5); /* Third shadow for extra depth */
}

.hover-scale:hover {
  transform: scale(1.2);
}

.instagram-logo {
  height: 40px !important;
}

.nav-margin {
  margin-top: 100px;
}

.fullscreen-overlay {
  background-color: black;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
}

.title-container {
}

.title {
  text-shadow: 0 0 12px rgb(0, 0, 0);
  color: white;
}

.subtitle {
  text-shadow: 0 0 12px rgb(0, 0, 0);
  color: rgb(190, 190, 190);
}

@media only screen and (max-width: 1024px) {
  .gradient-background {
    background: linear-gradient(
      145deg,
      #3e0020,
      #000000,
      #000021,
      #000000,
      #3e0020
    );
    background-size: 200% 200%;
    animation: gradient-animation-mobile 15s ease-in-out alternate infinite;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient-animation-mobile {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
